<template>
  <div>
    <bread-crumb :name="'Shop categories'"></bread-crumb>
    <div class="ps-page--shop" id="shop-categories">
      <div class="ps-container">
        <div class="ps-catalog-top">
          <div class="row">
            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="ps-block--menu-categories" data-mh="catalog-top">
                <div class="ps-block__header">
                  <h3>Categories</h3>
                </div>
                <div class="ps-block__content">
                  <ul class="ps-list--menu-cateogories">
                    <li v-for="category in categories" class="menu-item-has-children" :key="category.id">
                      <a href="#">{{ category.name }}</a>
                      <ul class="sub-menu">
                        <li
                          v-for="sub_category in category.sub_catgeories"
                          :key="sub_category.id"
                        >
                          <a href="#">{{ sub_category.name }}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="card-section">
                <div class="card-container">
                  <ShopCategoryCard
                    v-for="shopCategory in shopCategories"
                    :key="shopCategory.id"
                    :shop_category="shopCategory"
                  ></ShopCategoryCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/Navs/BreadCrumb";
import ShopCategoryCard from "@/components/Cards/ShopCategoryCard";
import axios from "axios";
export default {
  components: { BreadCrumb, ShopCategoryCard },
  data() {
    return {
      categories: [],
      shopCategories: []
    }
  },
  mounted() {
    this.getCategories();
    this.getShopCategories();
  },
  methods: {

    getCategories() {
      axios.get('categories').then(response => {
        this.categories = response.data
      }).catch(error => {
      })
    },

    getShopCategories() {
      axios.get( 'shops/categories').then(response => {
        this.shopCategories = response.data
        this.loading = false;
      }).catch(error => {
        this.loading = false;
      })
    },

  }
};
</script>

<style scoped>
.card-frame > .card-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 5px;
}

.card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 5rem 6rem;
  background-color: #F1F1F1; */
}

@media screen and (max-width: 768px) {
  .card-section {
    padding: 3rem 3rem;
  }

  .search-input {
    width: 500px;
  }
}

@media screen and (max-width: 575px) {
  .card-section {
    padding: 0rem;
    padding-top: 4rem;
  }
  .search-input {
    width: 500px;
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .card-container {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 575px) {
  .card-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    row-gap: 0.8rem;
  }
}

.card-frame {
  height: 25rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

@media screen and (max-width: 575px) {
  .card-frame {
    height: 10rem;
  }
}

.card-frame[card="fast"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/fast_food.webp");
}

.card-frame[card="fast"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.6);
}

.card-frame[card="pharmacy"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/pharmacy.webp");
}

.card-frame[card="pharmacy"] > .card-overlay {
  background-color: rgba(60, 3, 70, 0.6);
}

.card-frame[card="electronics"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/electronics.webp");
}

.card-frame[card="electronics"] > .card-overlay {
  background-color: rgba(13, 70, 3, 0.6);
}

.card-frame[card="grocery"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/grocery.webp");
}

.card-frame[card="grocery"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hair_beauty"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hair_beauty.webp");
}

.card-frame[card="hair_beauty"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.5);
}

.card-frame[card="house_home"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/house.webp");
}

.card-frame[card="house_home"] > .card-overlay {
  background-color: rgba(38, 95, 109, 0.6);
}

.card-frame[card="construction"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/construction.webp");
}

.card-frame[card="construction"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hardware"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hardware.webp");
}

.card-frame[card="hardware"] > .card-overlay {
  background-color: rgba(6, 69, 153, 0.6);
}

.card-frame[card="vehicles"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/vehicles.webp");
}

.card-frame[card="vehicles"] > .card-overlay {
  background-color: rgba(211, 42, 42, 0.6);
}

.card-frame[card="solar"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/solar.webp");
}

.card-frame[card="solar"] > .card-overlay {
  background-color: rgba(23, 167, 131, 0.6);
}

.card-frame[card="entertainment"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/entertainment.webp");
}

.card-frame[card="entertainment"] > .card-overlay {
  background-color: rgba(238, 48, 136, 0.6);
}

.card-frame[card="funeral"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/funeral.webp");
}

.card-frame[card="funeral"] > .card-overlay {
  background-color: rgba(63, 68, 65, 0.6);
}

.card-frame[card="gifts"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/treats.webp");
}

.card-frame[card="gifts"] > .card-overlay {
  background-color: rgba(201, 58, 77, 0.6);
}

.card-heading {
  font-size: 1.7em;
  text-align: center;
  padding: 2.5rem 0;
  padding-top: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .card-heading {
    padding: 1.5rem 0;
  }
}

.card-title {
  color: #ffffff;
  font-size: 1.2em !important;
  margin: 0;
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 575px) {
  .card-title {
    font-size: 1em;
  }
}

.card-text {
  padding: 2rem;
  padding-right: 0.5rem;
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
}

.card-text > p {
  margin: 0;
  padding: 0;
  font-size: 0.96em;
  margin: auto 0;
  color: #fff;
}

@media screen and (max-width: 575px) {
  .card-text > p {
    display: none;
  }
}

.card-text > .btn-primary {
  padding: 10px;
  min-width: 130px;
  font-size: 15px;
}

@media screen and (max-width: 575px) {
  .card-text {
    flex: 1;
    padding: 0.7rem 1rem;
    justify-content: space-between;
  }
}

.card-logo {
  background-color: #fff;
  position: relative;
  flex: 1;
  -webkit-clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  height: 100%;
}

.card-logo > img {
  max-width: 60%;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

@media screen and (max-width: 575px) {
  .card-logo {
    display: none;
  }
}

.card-paragraph {
  padding: 0 !important;
}
</style>>
